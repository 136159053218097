import React, { useEffect, useState, memo } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import Toaster from '../Toaster';

const CheckNetworkStatus = memo(() => {
  const { t } = useTranslation();

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showToast, setShowToast] = useState(false);

  const offlineToastId = 'offline';
  const onlineToastId = 'online';

  function updateStatus() {
    setIsOnline(navigator.onLine);
  }

  useEffect(() => {
    window.addEventListener('online', updateStatus);
    window.addEventListener('offline', () => {
      setShowToast(true);
      updateStatus();
    });

    return () => {
      window.removeEventListener('online', updateStatus);
      window.removeEventListener('offline', updateStatus);
    };
  }, []);

  useEffect(() => {
    console.log({ isOnline });
    if (!isOnline && showToast && !toast.isActive(offlineToastId)) {
      if (toast.isActive(onlineToastId)) {
        toast.dismiss(onlineToastId);
      }
      toast.dark(
        <Toaster icon="warning" message={t('internet_disconnected')} />,
        {
          toastId: offlineToastId,
        }
      );
    } else if (isOnline && showToast) {
      if (toast.isActive(offlineToastId)) {
        toast.dismiss(offlineToastId);
      }
      toast.dark(<Toaster icon="notify" message={t('internet_connected')} />, {
        toastId: onlineToastId,
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
  }, [isOnline]);
  return null;
});

export default CheckNetworkStatus;
