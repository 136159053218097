// French Language
export default {
  email: 'Email',
  password: 'Mot de passe',
  phone_number: 'Numéro de téléphone',
  email_required: 'Email est requis',
  password_required: 'Mot de passe requis',
  email_valid: 'Email doit être un email valide',
  password_regex_message: 'Mot de passe est incorrect',
  password_min: 'Mot de passe doit contenir au moins 6 caractères',
  login: 'Continuer à connexion',
  login_account: 'Connectez-vous à votre compte.',
  forgot_password: 'Mot de passe oublié',
  account: 'Ne pas avoir un compte?',
  contact_support: 'Contactez le support',
  send_recovery_link: 'envoyez-moi un lien de récupération',
  register_email:
    'Nous vous enverrons un mot de passe lien de réinitialisation sur votre compte e-mail enregistrée.',
  enter_your_email: 'entrer votre Email',
  reset_password: 'réinitialiser le mot de passe',
  looks_new_account: 'On dirait un nouveau compte',
  create_new_password_text: "S'il vous plaît créer un nouveau mot de passe",
  new_password: 'nouveau mot de passe',
  confirm_password: 'Confirmez le mot de passe',
  create_password: 'créer un mot de passe',
  password_not_match: 'Mot de passe ne correspond pas',
  track_event: 'Un événement',
  resend_alert: 'alerte Renvoyer',
  first_name: 'Prénom',
  last_name: 'nom de famille',
  contact: 'Numéro de contact',
  organization: 'Organisation',
  save_changes: 'Sauvegarder les modifications',
  assume_role: 'Supposons Rôle',
  user_privilege: "Privilège de l'utilisateur",
  activities: 'Activités',
  apartment: 'Appartement / suite / numéro de bureau',
  take_me_back: 'reprends moi',
  page_not_found: 'Page non trouvée',
  page_not_found_text: "La page que vous essayez d'atteindre n'existe pas.",
  create_alert: 'Créer une alerte',
  creating_alert: "Création d'une alerte",
  state: 'Etat',
  city: 'Ville',
  sca_history: 'histoire SCA',
  send_sca_alerts: 'Envoyer SCA Alertes',
  sending_sca_alerts: "Envoi d'alertes SCA",
  firstname_req: 'Le prénom est requis',
  lastname_req: 'Le nom est requis',
  phone_req: 'numéro de téléphone est nécessaire',
  phone_valid: "S'il vous plaît entrer un numéro de téléphone valide!",
  email_req: 'Email est requis',
  role_req: 'Le rôle est nécessaire',
  city_req: 'La ville est requise',
  state_req: 'État est tenu',
  country_req: 'Pays est nécessaire.',
  country: 'Pays',
  zipcode: 'Code postal (optionnel)',
  enter_phone: 'Entrez le numéro de téléphone',
  timezone: 'Fuseau horaire',
  timezone_req: 'Fuseau horaire est nécessaire',
  user_mgmt: 'Gestion des utilisateurs',
  user_mgmt_vol: 'Gestion des utilisateurs - Les bénévoles',
  user_mgmt_aed: 'Gestion des utilisateurs - AED Propriétaires',
  user_mgmt_disp: 'Gestion des utilisateurs - Admin / Dispatcher',
  user_mgmt_juri: 'Gestion des utilisateurs - Compétence',
  user_mgmt_aed_equipment: 'Gestion des utilisateurs - AED Équipement',
  vol_details: 'Détails des bénévoles -',
  aed_details: 'Détails AED Propriétaire -',
  admin_details: 'Détails Admin -',
  '': '',
  disp_details: 'Dispatcher Détails -',
  dispAdmin_detais: 'Dispatch admin Détails -',
  create_user: 'Créer un utilisateur',
  sca_events: 'Événements SCA',
  ongoing_sca_events: 'Événements en cours SCA',
  past_events: 'Événements passés',
  event_details: "Détails de l'évènement",
  dashboard: 'Tableau de bord',
  no_data_yet: 'Pas encore de données',
  add_jurisdiction: 'Ajouter Compétence',
  new_jurisdiction: 'nouvelle compétence',
  create_jurisdiction: 'Créer compétence',
  edit_jurisdiction: 'Compétence Enregistrer',
  jurisdiction_details: 'Détails de compétence',
  label_of_the_jurisdiction: 'Libellé de la compétence',
  not_suporting_jurisdiction:
    "À l'heure actuelle, nous ne soutenons pas ce pays pour créer la compétence. S'il vous plaît contacter l'administrateur.",
  confirm_event_close: 'Etes-vous sûr de vouloir fermer cet événement?',
  confirm_notify_again:
    'Etes-vous sûr de vouloir en informer à nouveau les bénévoles disponibles?',
  confirm_delete_area:
    'Etes-vous sûr de vouloir supprimer certains secteurs de compétence?',
  confirm_delete_aeds: 'Etes-vous sûr de vouloir supprimer DAE sélectionnés?',
  confirm_impersonate_exit: 'Voulez-vous quitter le mode faisant passer?',
  confirm_impersonate:
    'Êtes-vous sûr de vouloir passer en mode faisant passer comme',
  confirm_delete_users:
    'Etes-vous sûr de vouloir supprimer les utilisateurs sélectionnés?',
  confirm_delete: 'Etes-vous sûr que vous voulez supprimer?',
  yes: 'Oui',
  no: 'Non',
  something_wrong: 'Quelque chose a mal tourné !!!',
  select_state: 'Sélectionnez états',
  select_cities: 'Sélectionnez les villes',
  select_zipcode: 'Sélectionnez Zipcodes',
  select_counties: 'Sélectionnez les comtés',
  export_pdf: 'PDF Export',
  share_pdf: 'Partager PDF',
  resend_credentials: 'RENVOYER de vérification des pouvoirs',
  add_user: 'Ajouter un utilisateur',
  jurisdiction_req: 'La compétence est nécessaire.',
  jurisdiction_name_req: 'Nom de la compétence est nécessaire.',
  county_req: "S'il vous plaît sélectionner au moins un comté.",
  states_req: "S'il vous plaît sélectionner au moins un État.",
  cities_req: "S'il vous plaît sélectionner au moins une ville.",
  zipcodes_req: "S'il vous plaît sélectionner au moins un NPA.",
  jurisdiction: 'Juridiction',
  select_jurisdiction: 'Compétence Sélectionnez',
  no_volunteers: 'Pas encore bénévoles.',
  no_aed_owners: 'Pas encore AED Propriétaires.',
  no_jurisdictions: 'Aucun pays encore.',
  no_user: 'Non Admin, Dispatcher ou encore Dispatch Administrateur.',
  no_sca: "Pas encore d'événements.",
  no_aed_equipment: 'Pas encore AED Equipements.',
  no_activities: "Pas encore d'activités.",
  create_sca_success: 'événement SCA a créé avec succès.',
  sca_expired: 'Événement a expiré en raison du système time-out',
  search_location: "Recherche d'un emplacement ....",
  latitude: 'Latitude',
  longitude: 'Longitude',
  enter_sca_address: "Entrez l'adresse de l'événement SCA",
  or_enter_latLong: 'Ou Entrez Latitude et Longitude',
  or_search_address: 'Ou Rechercher une adresse',
  invalid_signature: 'Signature non valide!!',
  search: 'Chercher…',
  close: 'Fermer',
  internet_disconnected:
    "Internet a été déconnecté. S'il vous plaît essayez de résoudre les paramètres réseau.",
  internet_connected: 'Vous êtes maintenant connecté!',
  start_adding:
    'Commencer à ajouter certains, en cliquant sur le bouton ci-dessous',
  generate_report: 'Générer un rapport',
  sca_attributes: 'Attributs SCA',
  volunteer_attributes: 'Attributs bénévoles',
  aed_equip_attributes: 'AED Équipement Attributs',
  select_certificates: 'Sélectionnez Certificats',
  reports: 'Rapports',
  sca_event_reports: "SCA Rapports d'événements",
  volunteer_reports: 'Rapports bénévoles',
  aed_reports: 'AED Equipements',
  clear_filters: 'Effacer les filtres',
  valid_date: "S'il vous plaît sélectionner valide date Range!",
  select_date_from: 'Sélectionnez Date',
  select_date_to: 'Sélectionnez la date',
  sca_event_history: 'SCA Historique des événements',
  sca_event_detail: "SCA Détails de l'événement",
  aed_not_imported:
    "Impossible d'importer DAE suivants en raison de données non valides. S'il vous plaît entrer des données valides pour importer AED avec succès!",
  no_volunteer_responded: 'Aucun volontaire a répondu',
  import_aed: 'importation AED',
  import_csv: 'importation CSV',
  edit_csv: 'Modifier les données CSV',
  create_aed_owner: 'Créer AED propriétaire',
  assign_aed: 'Assigner AED',
  cancel: 'Annuler',
  confirm_unassign_Aed:
    "Etes-vous sûr, vous voulez supprimer l'affectation de cette AED?",
  no_result: 'Aucun enregistrement trouvé!',
  share: 'Partager',
  enter_email: 'Email et appuyez sur `Enter`',
  email_not_valid: 'est pas une adresse e-mail valide.',
  email_already_added: 'a déjà été ajouté.',
  responded_event: "a répondu à l'événement.",
  en_route_aed: "en route vers l'AED.",
  en_route_sca_with_aed: "en route vers l'événement SCA avec un DEA.",
  aed_equipped: 'Dispositif AED équipé',
  en_route_sca_without_aed: "en route vers l'événement SCA sans AED.",
  aed_not_found: 'Vous ne trouvez pas le AED.',
  en_route_sca: "En route vers l'événement SCA.",
  arrived_sca: 'Arrivé chez SCA Lieu',
  ems_arrived: 'EMS Arrivée',
  event_expire_auto: 'Événement expiré automatiquement.',
  exited_sca: 'SCA Exited',
  start_typing_initials: 'Début Initiales dactylographie',
  select_all: 'Tout sélectionner',
  version: 'Version',
  impersonating_as: 'comme se faisant passer',
  exit_impersonating: 'Quitter le mode Impersonating',
  logout: 'Se déconnecter',
  upload: 'Télécharger',
  mandatory_fields:
    "faire s'il vous plaît vous que les champs suivants sont obligatoires dans le document CSV.",
  manufacturer: 'Fabricant',
  model_number: 'Numéro de modèle',
  serial_number: 'Numéro de série',
  installation_date: "Date d'installation",
  location_details: "Détails de l'emplacement",
  pad_details: 'pad Détails',
  battery_details: 'Détails batterie',
  aed_detail: 'Détail AED',
  aed_image: 'image AED',
  street_view_image: 'Image Street View',
  street_image: 'Photo rue',
  last_checked_on: 'Dernier contrôle des',
  site_name: 'Nom du site',
  floor_sub_location: 'Plancher / sous-emplacement',
  location: 'Emplacement',
  notes: 'Remarques',
  availability: 'Disponibilité',
  public: 'Publique',
  private: 'Privé',
  zoll_equipments: 'Equipements médicaux Zoll',
  Others: 'Autres',
  optional: 'Optionnel',
  volunteer_detail: 'Détail des bénévoles',
  new_alert: 'nouvelle alerte',
  aeds_nearby: 'DAE à proximité',
  volunteers_nearby: 'Les bénévoles à proximité',
  send_alert_to_all:
    "Envoyer une alerte à tous les bénévoles actifs à proximité de l'emplacement SCA.",
  geolocation_not_enabled:
    "Géolocalisation n'est pas activé. S'il vous plaît permettre d'utiliser cette fonction.",
  geolocation_not_supported: 'La géolocalisation est pas pris en charge!',
  search_sca_area: 'Rechercher zone SCA, code PIN ...',
  active_sca: 'événements SCA actifs',
  no_active_case: 'Aucun cas actifs',
  volunteers_accepted_event: "Les bénévoles ont accepté l'événement",
  no_one_accepted_event: "Personne n'a encore accepté l'événement SCA.",
  ems_onsite: 'EMS sur place',
  started_at: 'Commencé à',
  pass_change_succ: 'Le mot de passe a été changé avec succès!',
  something_wrong_try_later:
    "Quelque chose c'est mal passé. Merci d'essayer plus tard!",
  password_hint_text:
    'Le mot de passe doit être au minimum de 6 caractères. Il devrait y avoir au moins 1 symbole ou un numéro et une lettre majuscule.',
  back_to_login: 'Retour connexion',
  select_duration_sca: "Sélectionner la durée de l'événement SCA",
  lt_5_mins: 'Moins de 5 minutes',
  range5to10: '5 - 10 minutes',
  gt_10_mins: 'Plus de 10 minutes',
  event_response: "Réponse de l'événement",
  all: 'Tout',
  no_response: 'Pas de réponse',
  assisted: 'assistée',
  not_assisted: 'non assistée',
  non_assisted: 'non assisté',
  at: 'à',
  date_range: 'Gamme Date',
  role: 'Rôle',
  only_volunteer: 'VOLONTAIREMENT',
  also_aed_owner: 'Aussi un propriétaire AED',
  also_volunteer: 'Aussi bénévole',
  volunteer_certifications: 'Types de certification volontaires',
  aed_equipment: 'AED Équipement',
  registered_aed: 'enregistré AED',
  imported_aed: 'Importé AED',
  manufacturer_and_model: 'Fabricant et modèle',
  aed_owners: 'AED Propriétaires',
  created_by_admin: 'Créé par Admin',
  created_by_app: 'Créé par Application mobile',
  owner_name: 'Nom du propriétaire AED',
  organization_name: "nom de l'organisation",
  number_aed: "Nombre d'appareils AED",
  devices: 'Dispositifs',
  more: 'plus',
  new: 'Nouveau',
  users: 'Utilisateurs',
  admins: 'admins',
  dispatcher: 'Dispatcher',
  dispatch_admins: 'Admins Dispatch',
  user_name: "Nom de l'utilisateur",
  user_type: "Type d'utilisateur",
  jurisdictions: 'juridictions',
  jurisdiction_labels: 'Étiquettes de compétence',
  areas_covered: 'Domaines couverts',
  counties: 'comtés',
  cities: 'Villes',
  volunteers: 'Bénévoles',
  active_users: 'utilisateurs actifs',
  non_active_users: 'Utilisateurs non-actifs',
  pending_approval: 'En attente de validation',
  volunteer_name: 'Nom de bénévoles',
  certifications: 'certifications',
  address: 'Adresse',
  status: 'Statut',
  today: "Aujourd'hui",
  joined_as: 'rejoint lien AED comme',
  added: 'Ajoutée',
  removed: 'Suppression',
  added_jurisdiction: 'ajouté compétence',
  removed_jurisdiction: 'compétence retirée',
  activated_volunteer: 'activé volontaire',
  de_activated_volunteer: 'volontaire activé De',
  changed_role_from: 'un nouveau rôle de',
  to: 'à',
  last_week: 'La semaine dernière',
  this_month: 'Ce mois-ci',
  last_month: 'Le mois dernier',
  last_2_month: 'Les 2 derniers mois',
  zoll_aed_equipment: 'ZOLL AED Équipement',
  other_aed_equipment: 'Autres marques AED Équipement',
  breathing_restored: 'La respiration restaurée',
  breathing_not_restored: 'La respiration non restaurée',
  owner_details: 'Détails du propriétaire',
  customer_name: 'Nom du client',
  access: 'Accès',
  timings: 'timings',
  created_by: 'Créé par',
  aed_devices: 'AED Devices',
  sca_events_served: 'SCA événements ont',
  total_volunteers: 'total des bénévoles',
  total_aed_equipment: 'Total AED Équipement',
  quick_analysis: 'Analyse rapide',
  average_sca_completion: 'délai moyen SCA',
  minutes: 'Minutes',
  aed_equipments_registered: 'AED Equipements Enregistré',
  ratio_of_gender: 'Ratio du sexe du patient',
  ratio_of_age: "Ratio de l'âge des patients",
  ratio_of_breathing:
    'Ratio des patients dont la respiration est a été restaurée',
  average_between_sca_acceptance_first_shock:
    "Le délai moyen entre l'acceptation de SCA et le premier choc délivré",
  sca_closed_succ: 'SCA événement avec succès fermé',
  sca_event_location: "SCA Lieu de l'événement",
  sca_dispatched_by: 'SCA Distribué par',
  date_and_time: 'Date et heure',
  event_log: 'Journal des événements',
  survey_report: "Rapport d'enquête",
  survey_from_volunteers: "Rapport d'enquête de bénévoles",
  volunteers_notified: 'Les bénévoles avisés',
  volunteer_address: 'Adresse de bénévoles',
  admin: 'admin',
  dispatchAdmin: 'Dispatch Administrateur',
  mobile_app: 'Application mobile',
  settings: 'Réglages',
  preferred_language:
    "S'il vous plaît sélectionner la langue préférée par le bas",
  language: 'Langue',
  time_zone_admin: "Ceci est votre fuseau horaire défini par l'administrateur.",
  jurisdiction_info: 'Informations sur la compétence',
  arriving_in: 'En arrivant à',
  min: 'min',
  download_app: "Télécharger l'application",
  volunters_save_lives: "L'organisation de nos bénévoles pour sauver des vies.",
  demo_description:
    "Notre application permet le suivi des bénévoles victimes d'arrêt cardiaque et de fournir tout le soutien possible avant que le SME arrive. Voici comment nous le faisons.",
  mobile_app_btn: 'Application mobile',
  web_dash_btn: 'Tableau de bord Web',
  expolore_app: 'Cliquez ici pour voir RescuerLink Demo',
  demo_login: "S'identifier",
  male: 'Masculin',
  female: 'Femme',
  unknown: 'Inconnue',
  years_0TO_5: '0 - 5 ans',
  years_5TO10: '5 - 10 ans',
  years_10TO15: '10 - 15 ans',
  years_15TO20: '15 - 20 ans',
  years_20TO25: '20 - 25 ans',
  years_25TO30: '25 - 30 ans',
  years_30TO35: '30 - 35 ans',
  years_35TO40: '35 - 40 ans',
  years_40TO45: '40 - 45 ans',
  years_45TO50: '45 - 50 ans',
  years_50TO55: '50 - 55 ans',
  years_55TO60: '55 - 60 ans',
  years_60TO65: '60 - 65 ans',
  years_65TO70: '65 - 70 ans',
  years_70TO75: '70 - 75 ans',
  years_75TO80: '75 - 80 ans',
  years_80TO85: '80 - 85 ans',
  years_85TO90: '85 - 90 ans',
  GT_90_years: 'Années 90+',
  gender_question: 'Quel était le sexe du patient?',
  age_question: 'âge estimé du patient?',
  cpr_given: "RCR a été donné avant l'arrivée 911?",
  cpr_given_time: 'Durée estimée de livraison RCR?',
  pads_placed: 'Les tampons ont été AED placé sur le patient?',
  pads_placed_time: 'temps estimé lorsque les électrodes ont été placées?',
  aed_turned_on: 'AED a été activé?',
  aed_turned_on_time: 'Temps estimé, quand il a été allumé?',
  patient_shocked: "Est-ce que l'AED jamais choquer le patient?",
  patient_shocked_time: 'temps estimé et le nombre de chocs livrés?',
  number_of_shocks: 'Entrez le nombre de protection contre les chocs Livré',
  breathing_restored_question:
    'Est-ce que le patient ont leur rétabli la respiration.',
  breathing_restored_time: 'Temps estimé, lors de la restauration respiration?',
  transported_hospital: "Le patient a été transporté à l'hôpital?",
  other_comments:
    "D'autres commentaires / préoccupations non référencées qui peuvent être utiles pour le médecin?",
  restart_demo: 'Relancer la démo',
  copy_auth_token: 'Copie Auth jeton',
  copied_to_clipboard: 'Copié dans le presse-papier!',
};
