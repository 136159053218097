import React, { useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-promise-loader';

import { ToastContainer, toast } from 'react-toastify';

import { AuthContext, useAuthContext } from './context/auth';
import { RoleContext, useRoleContext } from './context/changeRole';

import routes from './routes';

import 'mapbox-gl/dist/mapbox-gl.css';

import CheckNetworkStatus from './components/CheckNetworkStatus';

toast.configure();

function getHomePageOfUser(user, loggedUser) {
  if (user._id === loggedUser._id && user.isFirstLogin) {
    return '/resetPassword';
  }

  switch (user.role) {
    case 'dispatcher':
      return '/sca';
    case 'admin':
      return '/dashboard';
    case 'dispatchAdmin':
      return '/dashboard';
    default:
      return '/sca-events-list';
  }
}

function App() {
  // Check localstorage for existing tokens
  const existingToken = localStorage.getItem('token');
  const assumeRoleOf = JSON.parse(localStorage.getItem('assumeRoleOf'));
  const existingUser = JSON.parse(localStorage.getItem('user'));

  // Initialize App state with existing token, if exists.
  const [authUser, setAuthUser] = useState({
    user: existingUser,
    token: existingToken,
  });

  const [assumedUser, setAssumedUser] = useState({
    user: assumeRoleOf !== null ? assumeRoleOf : existingUser,
  });

  // Extend "setAuthTokens" function to update localstorage when tokens gets changed
  // Pass this function as a context, so that it can be called from anywhere to update the tokens
  const setAuthUserExtended = (user, token) => {
    if (token) {
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }
    setAuthUser({ user, token });
  };

  const setAssumedUserExtended = (user) => {
    localStorage.setItem('assumeRoleOf', JSON.stringify(user));
    setAssumedUser({ user });
  };

  return (
    <>
      <AuthContext.Provider
        value={{ authUser, setAuthUser: setAuthUserExtended }}
      >
        <RoleContext.Provider
          value={{ assumedUser, setAssumedUser: setAssumedUserExtended }}
        >
          <Suspense
            fallback={
              <Loader
                className="d-flex align-items-center justify-content-center"
                promiseTracker={usePromiseTracker}
                color="#233c86"
                background="rgba(255,255,255,0.255)"
              />
            }
          >
            <Router>
              <Switch>
                {routes.publicRoutes.map((route) => (
                  <Route key={route.path} path={route.path} exact={route.exact}>
                    <route.component />
                  </Route>
                ))}
                {routes.privateRoutes.map((route) => (
                  <PrivateRoute
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    ableToAccess={route.ableToAccess}
                  >
                    <route.component tabs={route.tabs} />
                  </PrivateRoute>
                ))}
                <Route path="*">
                  <Redirect to={{ pathname: '/page-not-found' }} />
                </Route>
              </Switch>
            </Router>
          </Suspense>
          <div className="h-100 w-100">
            <Loader
              className="d-flex align-items-center justify-content-center"
              promiseTracker={usePromiseTracker}
              color="#233c86"
              background="rgba(255,255,255,0.255)"
            />
          </div>
          <ToastContainer
            position="bottom-right"
            autoClose={false}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
          />
        </RoleContext.Provider>
      </AuthContext.Provider>
      <CheckNetworkStatus />
    </>
  );
}

// TODO: This, <PrivateRoute> component can have it own separate folder in ./src/components and then exported in this file.
// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, path, exact, ableToAccess }) {
  const { authUser } = useAuthContext();
  const { assumedUser } = useRoleContext();

  return (
    <Route
      path={path}
      exact={exact}
      render={({ location }) => {
        // If not logged in, redirect to /login
        if (!authUser.token) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { referer: location } }}
            />
          );
        }

        if (authUser.user.isFirstLogin && path !== '/resetPassword') {
          return (
            <Redirect
              to={{ pathname: '/resetPassword', state: { referer: location } }}
            />
          );
        }

        // Check if the current user role has access to this route or not
        // If not able to access, redirect to their homepage

        const isAbleToAccess = ableToAccess.includes(assumedUser.user.role);

        if (!isAbleToAccess) {
          return (
            <Redirect
              to={{
                pathname: getHomePageOfUser(assumedUser.user, authUser.user),
              }}
            />
          );
        }
        if (path === '/') {
          return (
            <Redirect
              to={{
                pathname: getHomePageOfUser(assumedUser.user, authUser.user),
              }}
            />
          );
        }
        // If able to access, move forward
        return children;
      }}
    />
  );
}
PrivateRoute.propTypes = {
  children: PropTypes.element.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  exact: PropTypes.bool.isRequired,
  ableToAccess: PropTypes.array.isRequired,
};

export default App;
