// Indonesian Language

export default {
  email: 'Surel',
  password: 'Kata sandi',
  phone_number: 'Nomor telepon',
  email_required: 'Email diperlukan',
  password_required: 'katakunci dibutuhkan',
  email_valid: 'Email harus email yang valid',
  password_regex_message: 'Password tidak valid',
  password_min: 'Sandi harus terdiri dari minimal 6 karakter',
  login: 'Terus masuk',
  login_account: 'Masuk ke akun Anda.',
  forgot_password: 'Tidak ingat kata sandi',
  account: 'Tidak memiliki akun?',
  contact_support: 'Hubungi Dukungan',
  send_recovery_link: 'mengirim saya link recovery',
  register_email:
    'Kami akan mengirimkan link ulang password pada akun email Anda yang terdaftar.',
  enter_your_email: 'Masukkan email Anda',
  reset_password: 'Reset password',
  looks_new_account: 'Tampak seperti akun baru',
  create_new_password_text: 'Harap membuat password baru',
  new_password: 'kata sandi baru',
  confirm_password: 'konfirmasi sandi',
  create_password: 'membuat password',
  password_not_match: 'Kata sandi tidak cocok',
  track_event: 'track Acara',
  resend_alert: 'Kirim ulang Siaga',
  first_name: 'Nama depan',
  last_name: 'nama keluarga',
  contact: 'Nomor kontak',
  organization: 'Organisasi',
  save_changes: 'Simpan perubahan',
  assume_role: 'asumsikan Peran',
  user_privilege: 'Privilege pengguna',
  activities: 'Kegiatan',
  apartment: 'Apartemen / suite / nomor kantor',
  take_me_back: 'Bawa saya kembali',
  page_not_found: 'Halaman tidak ditemukan',
  page_not_found_text: 'Halaman yang Anda mencoba untuk mencapai tidak ada.',
  create_alert: 'Buat Alert',
  creating_alert: 'Membuat Alert',
  state: 'Negara',
  city: 'Kota',
  sca_history: 'sejarah SCA',
  send_sca_alerts: 'Kirim SCA Alerts',
  sending_sca_alerts: 'Mengirim Lansiran SCA',
  firstname_req: 'Nama depan diperlukan',
  lastname_req: 'nama terakhir diperlukan',
  phone_req: 'Nomor telepon diperlukan',
  phone_valid: 'Silahkan masukkan nomor telepon yang benar!',
  email_req: 'Email diperlukan',
  role_req: 'Peran diperlukan',
  city_req: 'Kota diperlukan',
  state_req: 'Negara diperlukan',
  country_req: 'Negara diperlukan.',
  country: 'Negara',
  zipcode: 'Kode Pos (Opsional)',
  enter_phone: 'Masukkan nomor telepon',
  timezone: 'Zona waktu',
  timezone_req: 'Timezone diperlukan',
  user_mgmt: 'manajemen pengguna',
  user_mgmt_vol: 'Manajemen user - Relawan',
  user_mgmt_aed: 'Manajemen user - AED Pemilik',
  user_mgmt_disp: 'Manajemen user - Admin / Dispatcher',
  user_mgmt_juri: 'Manajemen user - Yurisdiksi',
  user_mgmt_aed_equipment: 'Manajemen user - AED Peralatan',
  vol_details: 'Rincian relawan -',
  aed_details: 'Detail AED pemilik -',
  admin_details: 'Rincian Admin -',
  '': '',
  disp_details: 'Rincian Dispatcher -',
  dispAdmin_detais: 'Dispatch admin Rincian -',
  create_user: 'Buat pengguna',
  sca_events: 'SCA Acara',
  ongoing_sca_events: 'Sedang berlangsung SCA Acara',
  past_events: 'Acara terakhir',
  event_details: 'detail acara',
  dashboard: 'Dasbor',
  no_data_yet: 'Tidak ada data belum',
  add_jurisdiction: 'add Yurisdiksi',
  new_jurisdiction: 'Yurisdiksi baru',
  create_jurisdiction: 'Buat Yurisdiksi',
  edit_jurisdiction: 'Simpan Yurisdiksi',
  jurisdiction_details: 'Rincian yurisdiksi',
  label_of_the_jurisdiction: 'Label dari Yurisdiksi yang',
  not_suporting_jurisdiction:
    'Saat ini kami tidak mendukung Negara ini untuk membuat yurisdiksi. Silakan administrator kontak.',
  confirm_event_close: 'Apakah Anda yakin ingin menutup acara ini?',
  confirm_notify_again:
    'Apakah Anda yakin Anda ingin memberitahu relawan tersedia lagi?',
  confirm_delete_area:
    'Apakah Anda yakin ingin menghapus Yurisdiksi yang dipilih?',
  confirm_delete_aeds: 'Apakah Anda yakin ingin menghapus AED dipilih?',
  confirm_impersonate_exit: 'Apakah Anda ingin keluar dari mode meniru?',
  confirm_impersonate:
    'Apakah Anda yakin Anda ingin memasukkan modus menyamar sebagai',
  confirm_delete_users:
    'Apakah Anda yakin ingin menghapus pengguna yang dipilih?',
  confirm_delete: 'Apakah Anda yakin ingin menghapus?',
  yes: 'Iya',
  no: 'Tidak',
  something_wrong: 'Ada yang salah!!!',
  select_state: 'Pilih negara',
  select_cities: 'Pilih Kota',
  select_zipcode: 'Pilih zipcodes',
  select_counties: 'Pilih Counties',
  export_pdf: 'ekspor PDF',
  share_pdf: 'Share PDF',
  resend_credentials: 'Kredensial Kirim Ulang',
  add_user: 'Tambahkan pengguna',
  jurisdiction_req: 'Yurisdiksi diperlukan.',
  jurisdiction_name_req: 'Nama Yurisdiksi diperlukan.',
  county_req: 'Silakan pilih minimal satu County.',
  states_req: 'Silakan pilih minimal satu Negara.',
  cities_req: 'Silakan pilih minimal satu Kota.',
  zipcodes_req: 'Silakan pilih minimal satu Kodepos.',
  jurisdiction: 'Yurisdiksi',
  select_jurisdiction: 'Pilih Yurisdiksi',
  no_volunteers: 'Tidak ada relawan belum.',
  no_aed_owners: 'Tidak ada Kepemilikan AED belum.',
  no_jurisdictions: 'Belum ada yurisdiksi.',
  no_user: 'Tidak ada Admin, Dispatcher atau Dispatch Admin belum.',
  no_sca: 'Belum ada acara.',
  no_aed_equipment: 'Tidak ada AED Peralatan belum.',
  no_activities: 'Belum ada kegiatan.',
  create_sca_success: 'Acara SCA berhasil dibuat.',
  sca_expired: 'Acara telah kedaluwarsa karena sistem time-out',
  search_location: 'Mencari lokasi sebuah ....',
  latitude: 'Garis Lintang',
  longitude: 'Garis bujur',
  enter_sca_address: 'Masukkan SCA alamat acara',
  or_enter_latLong: 'Atau Masukkan Lintang dan Bujur',
  or_search_address: 'Atau Cari Alamat',
  invalid_signature: 'Valid tanda tangan !!',
  search: 'Cari…',
  close: 'Menutup',
  internet_disconnected:
    'Internet telah terputus. Silakan coba pemecahan masalah pengaturan jaringan.',
  internet_connected: 'Anda sekarang terhubung!',
  start_adding: 'Mulai menambahkan beberapa, dengan mengklik tombol di bawah',
  generate_report: 'menghasilkan Laporan',
  sca_attributes: 'Atribut SCA',
  volunteer_attributes: 'Atribut relawan',
  aed_equip_attributes: 'AED Peralatan Atribut',
  select_certificates: 'Pilih Sertifikat',
  reports: 'laporan',
  sca_event_reports: 'Laporan Peristiwa SCA',
  volunteer_reports: 'Laporan relawan',
  aed_reports: 'AED Peralatan',
  clear_filters: 'jelas Filter',
  valid_date: 'Pilih Tanggal berlaku Rentang!',
  select_date_from: 'Pilih Tanggal Dari',
  select_date_to: 'Pilih Tanggal Untuk',
  sca_event_history: 'SCA Sejarah Acara',
  sca_event_detail: 'Detil SCA Acara',
  aed_not_imported:
    'tidak bisa mengimpor AED berikut karena data yang tidak valid. Masukkan data yang valid untuk mengimpor AED berhasil!',
  no_volunteer_responded: 'Tidak ada relawan telah merespon',
  import_aed: 'impor AED',
  import_csv: 'impor CSV',
  edit_csv: 'Mengedit CSV data',
  create_aed_owner: 'Buat AED Pemilik',
  assign_aed: 'menetapkan AED',
  cancel: 'Membatalkan',
  confirm_unassign_Aed:
    'Apakah Anda yakin, Anda ingin membatalkan penetapan AED ini?',
  no_result: 'Tidak ada hasil yang ditemukan!',
  share: 'Bagikan',
  enter_email: 'Masukkan Email dan tekan `Enter`',
  email_not_valid: 'bukan alamat email yang valid.',
  email_already_added: 'telah ditambahkan.',
  responded_event: 'menanggapi acara tersebut.',
  en_route_aed: 'perjalanan ke AED.',
  en_route_sca_with_aed: 'perjalanan ke acara SCA dengan AED.',
  aed_equipped: 'perangkat AED dilengkapi',
  en_route_sca_without_aed: 'perjalanan ke acara SCA tanpa AED.',
  aed_not_found: 'Tidak menemukan AED.',
  en_route_sca: 'dalam perjalanan ke acara SCA.',
  arrived_sca: 'Tiba di SCA Lokasi',
  ems_arrived: 'EMS Tiba',
  event_expire_auto: 'Acara berakhir secara otomatis.',
  exited_sca: 'SCA keluar',
  exited_sca_eta:
    'dipaksa untuk keluar dari acara oleh sistem karena ETA yang lebih tinggi.',
  start_typing_initials: 'Mulai Mengetik Inisial',
  select_all: 'Pilih Semua',
  version: 'Versi: kapan',
  impersonating_as: 'meniru sebagai',
  exit_impersonating: 'Keluar meniru mode',
  logout: 'Keluar',
  upload: 'Upload',
  mandatory_fields:
    'Pastikan bahwa bidang berikut ini wajib dalam dokumen CSV.',
  manufacturer: 'Pabrikan',
  model_number: 'Nomor model',
  serial_number: 'Nomor seri',
  installation_date: 'Tanggal instalasi',
  location_details: 'lokasi Detail',
  pad_details: 'Rincian pad',
  battery_details: 'Rincian baterai',
  aed_detail: 'AED Detil',
  aed_image: 'AED Gambar',
  street_view_image: 'Street View Gambar',
  street_image: 'jalan Gambar',
  last_checked_on: 'Terakhir Diperiksa Pada',
  site_name: 'Nama situs',
  floor_sub_location: 'Lantai / Sub-lokasi',
  location: 'tempat',
  notes: 'Catatan',
  availability: 'Ketersediaan',
  public: 'Publik',
  private: 'Pribadi',
  zoll_equipments: 'Zoll Peralatan Medis',
  Others: 'Lainnya',
  optional: 'Pilihan',
  volunteer_detail: 'Detil relawan',
  new_alert: 'New Siaga',
  aeds_nearby: 'AED terdekat',
  volunteers_nearby: 'relawan terdekat',
  send_alert_to_all:
    'Kirim peringatan kepada semua relawan yang aktif di dekatnya lokasi SCA.',
  geolocation_not_enabled:
    'Geolocation tidak diaktifkan. Aktifkan untuk menggunakan fitur ini.',
  geolocation_not_supported: 'Geolocation tidak didukung!',
  search_sca_area: 'Mencari daerah SCA, kode pin ...',
  active_sca: 'Peristiwa SCA aktif',
  no_active_case: 'Tidak ada Kasus Aktif',
  volunteers_accepted_event: 'Relawan diterima acara',
  no_one_accepted_event: 'Tidak ada yang menerima acara SCA belum.',
  ems_onsite: 'EMS di lokasi',
  started_at: 'dimulai pada',
  pass_change_succ: 'Sandi berhasil diubah!',
  something_wrong_try_later: 'Sesuatu yang tidak beres, coba lagi nanti!',
  password_hint_text:
    'Password harus minimal 6 karakter. Ini harus memiliki minimal 1 simbol atau nomor dan 1 huruf.',
  back_to_login: 'Kembali untuk masuk',
  select_duration_sca: 'Pilih durasi acara SCA',
  lt_5_mins: 'Kurang dari 5 menit',
  range5to10: '5 - 10 menit',
  gt_10_mins: 'Lebih dari 10 menit',
  event_response: 'acara Response',
  all: 'Semua',
  no_response: 'Tidak ada respon',
  assisted: 'dibantu',
  not_assisted: 'tidak Assisted',
  non_assisted: 'Non dibantu',
  at: 'di',
  date_range: 'Rentang Tanggal',
  role: 'Wewenang',
  only_volunteer: 'hanya relawan',
  also_aed_owner: 'Juga pemilik AED',
  also_volunteer: 'Juga Relawan',
  volunteer_certifications: 'Jenis Sertifikasi Relawan',
  aed_equipment: 'AED Peralatan',
  registered_aed: 'terdaftar AED',
  imported_aed: 'Impor AED',
  manufacturer_and_model: 'Produsen & Model',
  aed_owners: 'AED Pemilik',
  created_by_admin: 'Dibuat oleh Admin',
  created_by_app: 'Dibuat oleh Mobile Application',
  owner_name: 'Nama AED pemilik',
  organization_name: 'Nama Organisasi',
  number_aed: 'Jumlah AED Devices',
  devices: 'perangkat',
  more: 'lebih',
  new: 'Baru',
  users: 'pengguna',
  admins: 'admin',
  dispatcher: 'dispatcher',
  dispatch_admins: 'dispatch Admin',
  user_name: 'Nama Pengguna',
  user_type: 'Tipe Pengguna',
  jurisdictions: 'yurisdiksi',
  jurisdiction_labels: 'yurisdiksi Label',
  areas_covered: 'Area Covered',
  counties: 'Counties',
  cities: 'kota',
  volunteers: 'relawan',
  active_users: 'pengguna aktif',
  non_active_users: 'pengguna non-aktif',
  pending_approval: 'Menunggu persetujuan',
  volunteer_name: 'Nama relawan',
  certifications: 'sertifikasi',
  address: 'Alamat',
  status: 'Status',
  today: 'Hari ini',
  joined_as: 'bergabung AED Link sebagai',
  added: 'ditambahkan',
  removed: 'dihapus',
  added_jurisdiction: 'ditambahkan yurisdiksi',
  removed_jurisdiction: 'yurisdiksi dihapus',
  activated_volunteer: 'diaktifkan relawan',
  de_activated_volunteer: 'relawan de-diaktifkan',
  changed_role_from: 'Peran berubah dari',
  to: 'untuk',
  last_week: 'Minggu lalu',
  this_month: 'Bulan ini',
  last_month: 'Bulan lalu',
  last_2_month: '2 bulan terakhir',
  zoll_aed_equipment: 'Zoll AED Peralatan',
  other_aed_equipment: 'Lainnya Merek AED Peralatan',
  breathing_restored: 'bernapas dipulihkan',
  breathing_not_restored: 'Bernapas tidak dipulihkan',
  owner_details: 'Rincian pemilik',
  customer_name: 'Nama Pelanggan',
  access: 'Mengakses',
  timings: 'timing',
  created_by: 'Dibuat oleh',
  aed_devices: 'AED Perangkat',
  sca_events_served: 'SCA Acara dilayani',
  total_volunteers: 'Jumlah Relawan',
  total_aed_equipment: 'Jumlah AED Peralatan',
  quick_analysis: 'Analisis cepat',
  average_sca_completion: 'Rata-rata SCA waktu penyelesaian',
  minutes: 'Menit',
  aed_equipments_registered: 'AED Peralatan Terdaftar',
  ratio_of_gender: 'Rasio jenis kelamin pasien',
  ratio_of_age: 'Rasio usia pasien',
  ratio_of_breathing: 'Rasio pasien ini yang bernapas Apakah dipulihkan',
  average_between_sca_acceptance_first_shock:
    'Rata-rata waktu antara penerimaan SCA dan kejutan pertama disampaikan',
  sca_closed_succ: 'SCA Acara ditutup dengan sukses',
  sca_event_location: 'SCA Acara Lokasi',
  sca_dispatched_by: 'SCA Dikirim oleh',
  date_and_time: 'Tanggal Waktu',
  event_log: 'event Log',
  survey_report: 'Laporan survei',
  survey_from_volunteers: 'Survey Laporan dari Relawan',
  volunteers_notified: 'relawan diberitahu',
  volunteer_address: 'Alamat relawan',
  admin: 'Admin',
  dispatchAdmin: 'dispatch Admin',
  mobile_app: 'Aplikasi ponsel',
  settings: 'pengaturan',
  preferred_language: 'Pilih bahasa yang diinginkan dari bawah',
  language: 'Bahasa',
  time_zone_admin: 'Ini adalah zona waktu yang ditetapkan oleh admin.',
  jurisdiction_info: 'Info yurisdiksi',
  arriving_in: 'Sesampainya Dalam',
  min: 'min',
  download_app: 'Unduh aplikasi',
  volunters_save_lives:
    'Pengorganisasian Relawan kami untuk menyelamatkan nyawa.',
  demo_description:
    'Aplikasi kami membantu relawan melacak korban penangkapan jantung dan memberikan setiap dukungan mungkin sebelum EMS tiba. Berikut adalah bagaimana kita melakukannya.',
  mobile_app_btn: 'Mobile App',
  web_dash_btn: 'web Dashboard',
  expolore_app: 'Klik di sini untuk melihat RescuerLink Demo',
  demo_login: 'Gabung',
  male: 'Pria',
  female: 'Perempuan',
  unknown: 'tidak diketahui',
  years_0TO_5: '0-5 Tahun',
  years_5TO10: '5 - 10 Tahun',
  years_10TO15: '10-15 Tahun',
  years_15TO20: '15-20 Tahun',
  years_20TO25: '20 - 25 Tahun',
  years_25TO30: '25 - 30 Tahun',
  years_30TO35: '30 - 35 Tahun',
  years_35TO40: '35 - 40 Tahun',
  years_40TO45: '40 - 45 Tahun',
  years_45TO50: '45 - 50 Tahun',
  years_50TO55: '50 - 55 Tahun',
  years_55TO60: '55 - 60 Tahun',
  years_60TO65: '60 - 65 Tahun',
  years_65TO70: '65-70 Tahun',
  years_70TO75: '70-75 Tahun',
  years_75TO80: '75-80 Tahun',
  years_80TO85: '80-85 Tahun',
  years_85TO90: '85 - 90 Tahun',
  GT_90_years: '90 + Tahun',
  gender_question: 'Apa jenis kelamin pasien?',
  age_question: 'Perkiraan usia pasien?',
  cpr_given: 'Apakah CPR diberikan sebelum 911 kedatangan?',
  cpr_given_time: 'Perkiraan waktu pengiriman CPR?',
  pads_placed: 'Apakah AED Pads ditempatkan pada pasien?',
  pads_placed_time: 'Perkiraan waktu ketika bantalan ditempatkan?',
  aed_turned_on: 'Itu AED dihidupkan?',
  aed_turned_on_time: 'Perkiraan waktu, ketika itu dihidupkan?',
  patient_shocked: 'Apakah AED pernah mengejutkan pasien?',
  patient_shocked_time: 'Perkiraan waktu & jumlah guncangan disampaikan?',
  number_of_shocks: 'Masukkan Jumlah Guncangan Disampaikan',
  breathing_restored_question:
    'Apakah pasien memiliki napas mereka dipulihkan.',
  breathing_restored_time: 'Perkiraan waktu, ketika bernapas dipulihkan?',
  transported_hospital: 'Apakah pasien diangkut ke rumah sakit?',
  other_comments:
    'Lain komentar / kekhawatiran tidak dirujuk yang mungkin berguna bagi Dokter medis?',
  restart_demo: 'Restart Demo',
  copy_auth_token: 'Copy Tupoksi Token',
  copied_to_clipboard: 'Disalin ke clipboard!',
};
