import React from 'react';

import componentLoader from './assets/utils/componentLoader';

// import PageNotFound from './pages/PageNotFound';
// import Dashboard from './pages/DashboardPage';
// import UserManagementPage from './pages/UserManagementPage';
// import ScaReportPage from './pages/ScaReportPage';
// import ScaEventsListPage from './pages/ScaEventsListPage';

const PageNotFound = React.lazy(() =>
  componentLoader(() => import('./pages/PageNotFound'))
);

const UserResetPasswordPage = React.lazy(() =>
  componentLoader(() => import('./pages/UserResetPasswordPage'))
);
const DispatcherResetPasswordPage = React.lazy(() =>
  componentLoader(() =>
    import('./pages/Dispatcher/DispatcherResetPasswordPage')
  )
);
const LoginPage = React.lazy(() =>
  componentLoader(() => import('./pages/Dispatcher/LoginPage'))
);
const ForgotPasswordPage = React.lazy(() =>
  componentLoader(() => import('./pages/Dispatcher/ForgotPasswordPage'))
);
const ResetPasswordPage = React.lazy(() =>
  componentLoader(() => import('./pages/Dispatcher/ResetPasswordPage'))
);
const ScaEventPage = React.lazy(() =>
  componentLoader(() => import('./pages/ScaEventPage'))
);
const UserManagementPage = React.lazy(() =>
  componentLoader(() => import('./pages/UserManagementPage'))
);
const OwnerDetailPage = React.lazy(() =>
  componentLoader(() => import('./pages/OwnerDetailPage'))
);
const UserDetailPage = React.lazy(() =>
  componentLoader(() => import('./pages/UserDetailPage'))
);
const VolunteerDetailPage = React.lazy(() =>
  componentLoader(() => import('./pages/VolunteerDetailPage'))
);
const VolunteerSCAHistoryPage = React.lazy(() =>
  componentLoader(() => import('./pages/VolunteerSCAHistoryPage'))
);
const VolunteerSCAHistoryDetailPage = React.lazy(() =>
  componentLoader(() => import('./pages/VolunteerSCAHistoryDetailPage'))
);
const ScaEventsListPage = React.lazy(() =>
  componentLoader(() => import('./pages/ScaEventsListPage'))
);
const TrackEventPage = React.lazy(() =>
  componentLoader(() => import('./pages/TrackEventPage'))
);
const DispatcherScaEventHistoryPage = React.lazy(() =>
  componentLoader(() => import('./pages/DispatcherScaEventHistoryPage'))
);
const CreateUserPage = React.lazy(() =>
  componentLoader(() => import('./pages/CreateUserPage'))
);
const ScaEventHistoryDetailPage = React.lazy(() =>
  componentLoader(() => import('./pages/ScaEventHistoryDetailPage'))
);
const CreateJurisdictionPage = React.lazy(() =>
  componentLoader(() => import('./pages/CreateJurisdictionPage'))
);
const JurisdictionDetailPage = React.lazy(() =>
  componentLoader(() => import('./pages/JurisdictionDetailPage'))
);
const AdminActivities = React.lazy(() =>
  componentLoader(() => import('./pages/AdminActivities'))
);
const Dashboard = React.lazy(() =>
  componentLoader(() => import('./pages/DashboardPage'))
);
const ScaReportPage = React.lazy(() =>
  componentLoader(() => import('./pages/ScaReportPage'))
);
const AedEquipmentDetailPage = React.lazy(() =>
  componentLoader(() => import('./pages/AedEquipmentDetailPage'))
);
const CreateAedOwnerPage = React.lazy(() =>
  componentLoader(() => import('./pages/CreateAedOwnerPage'))
);
const SettingPage = React.lazy(() =>
  componentLoader(() => import('./pages/SettingPage'))
);
const DemoPage = React.lazy(() =>
  componentLoader(() => import('./pages/DemoPage'))
);

const routes = {
  publicRoutes: [
    {
      path: '/login',
      exact: true,
      component: LoginPage,
    },
    {
      path: '/forgot-password',
      exact: true,
      component: ForgotPasswordPage,
    },
    {
      path: '/page-not-found',
      exact: true,
      component: PageNotFound,
    },
    {
      path: '/reset-password',
      exact: true,
      component: UserResetPasswordPage,
    },
    {
      path: '/password-reset',
      exact: true,
      component: DispatcherResetPasswordPage,
    },
    {
      path: '/demo',
      exact: true,
      component: DemoPage,
    },
  ],
  privateRoutes: [
    {
      path: '/',
      exact: true,
      ableToAccess: ['dispatcher', 'admin'],
      component: ScaEventPage,
    },
    {
      path: '/sca',
      exact: true,
      ableToAccess: ['dispatcher'],
      component: ScaEventPage,
    },
    {
      path: '/sca-events-list',
      exact: true,
      ableToAccess: ['admin', 'dispatchAdmin'],
      component: ScaEventsListPage,
    },
    {
      path: '/resetPassword',
      exact: true,
      ableToAccess: ['dispatcher', 'admin', 'dispatchAdmin'],
      component: ResetPasswordPage,
    },
    {
      path: '/user-managment/volunteer-detail/:userId',
      exact: true,
      ableToAccess: ['admin', 'dispatchAdmin'],
      component: VolunteerDetailPage,
    },
    {
      path: '/user-managment/volunteer-detail/sca-history/:userId',
      exact: true,
      ableToAccess: ['admin', 'dispatchAdmin'],
      component: VolunteerSCAHistoryPage,
    },
    {
      path:
        '/user-managment/volunteer-detail/sca-history/sca-event-history/:userId/:scaId',
      exact: true,
      ableToAccess: ['admin', 'dispatchAdmin'],
      component: VolunteerSCAHistoryDetailPage,
    },
    {
      path: '/user-managment',
      exact: true,
      ableToAccess: ['admin', 'dispatchAdmin'],
      component: UserManagementPage,
    },
    {
      path: '/user-managment/owner-detail/:userId',
      exact: true,
      ableToAccess: ['admin', 'dispatchAdmin'],
      component: OwnerDetailPage,
    },
    {
      path: '/user-managment/user-detail/:userId',
      exact: true,
      ableToAccess: ['admin', 'dispatchAdmin'],
      component: UserDetailPage,
    },
    {
      path: '/track-event',
      exact: true,
      ableToAccess: ['dispatcher', 'admin', 'dispatchAdmin'],
      component: TrackEventPage,
    },
    {
      path: '/sca-event-history',
      exact: true,
      ableToAccess: ['dispatcher'],
      component: DispatcherScaEventHistoryPage,
    },
    {
      path: '/user-managment/create-user',
      exact: true,
      ableToAccess: ['admin', 'dispatchAdmin'],
      component: CreateUserPage,
    },
    {
      path: '/user-managment/create-aed-owner',
      exact: true,
      ableToAccess: ['admin', 'dispatchAdmin'],
      component: CreateAedOwnerPage,
    },
    {
      path: '/user-managment/create-jurisdiction',
      exact: true,
      ableToAccess: ['admin', 'dispatchAdmin'],
      component: CreateJurisdictionPage,
    },
    {
      path: '/user-managment/jurisdiction-detail/:jurisdictionId',
      exact: true,
      ableToAccess: ['admin', 'dispatchAdmin'],
      component: JurisdictionDetailPage,
    },
    {
      path: '/user-managment/aed-equipment-detail/:aedId',
      exact: true,
      ableToAccess: ['admin', 'dispatchAdmin'],
      component: AedEquipmentDetailPage,
    },
    {
      path: ['/sca-event-history/:scaId', '/sca-events-list/:scaId'],
      exact: true,
      ableToAccess: ['dispatcher', 'dispatchAdmin', 'admin'],
      component: ScaEventHistoryDetailPage,
    },
    {
      path: '/user-managment/admin-activities/:userId',
      exact: true,
      component: AdminActivities,
      ableToAccess: ['dispatchAdmin', 'admin'],
    },
    {
      path: '/dashboard',
      exact: true,
      ableToAccess: ['admin', 'dispatchAdmin'],
      component: Dashboard,
    },
    {
      path: '/reports',
      exact: true,
      ableToAccess: ['admin', 'dispatchAdmin'],
      component: ScaReportPage,
    },
    {
      path: '/settings',
      exact: true,
      ableToAccess: ['admin', 'dispatchAdmin', 'dispatcher'],
      component: SettingPage,
    },
  ],
};

export default routes;
