import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
  english,
  german,
  french,
  malay,
  chinese,
  indonesian,
  thai,
  // canadianFrench,
  // dutch,
  // japanese,
  // spanish,
} from './locales';

const resources = {
  'en-US': {
    translation: english,
  },
  de: {
    translation: german,
  },
  fr: {
    translation: french,
  },
  ms: {
    translation: malay,
  },
  'zh-CN': {
    translation: chinese,
  },
  in: {
    translation: indonesian,
  },
  th: {
    translation: thai,
  },
};

/* Language Detector Code */
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    supportedLngs: ['en', 'de', 'fr', 'ms', 'zh', 'in', 'th'],
    nonExplicitSupportedLngs: true,
    // lng: 'en',
    translateFuncName: 't',
    resources,
    interpolation: {
      escapeValue: false,
    },
    wait: true,
  });

i18n.changeLanguage();
export default i18n;
