// Chinese Language
export default {
  email: '电子邮件',
  password: '密码',
  phone_number: '电话号码',
  email_required: '电子邮件是必需的',
  password_required: '密码是必需的',
  email_valid: '电子邮件必须是一个有效的电子邮件',
  password_regex_message: '密码无效',
  password_min: '密码必须包含至少6个字符',
  login: '继续登录',
  login_account: '登录到您的帐户。',
  forgot_password: '忘记密码',
  account: '还没有账号？',
  contact_support: '联系支持',
  send_recovery_link: '给我一个恢复链接',
  register_email: '我们将向您发送您的注册电子邮件帐户密码重置链接。',
  enter_your_email: '输入你的电子邮箱',
  reset_password: '重设密码',
  looks_new_account: '看起来像一个新的帐户',
  create_new_password_text: '请创建新密码',
  new_password: '新密码',
  confirm_password: '确认密码',
  create_password: '创建密码',
  password_not_match: '密码不匹配',
  track_event: '事件追踪',
  resend_alert: '重发警报',
  first_name: '名字',
  last_name: '姓',
  contact: '联系电话',
  organization: '组织',
  save_changes: '保存更改',
  assume_role: '假设角色',
  user_privilege: '用户权限',
  activities: '活动',
  apartment: '公寓/套件/办公室号码',
  take_me_back: '带我回去',
  page_not_found: '网页未找到',
  page_not_found_text: '您尝试访问的页面不存在。',
  create_alert: '创建警报',
  creating_alert: '创建警报',
  state: '州',
  city: '市',
  sca_history: 'SCA历史',
  send_sca_alerts: '发送SCA警报',
  sending_sca_alerts: '发送SCA警报',
  firstname_req: '名字为必填',
  lastname_req: '姓氏为必填',
  phone_req: '电话号码是必需的',
  phone_valid: '请输入一个有效的电话号码！',
  email_req: '电子邮件是必需的',
  role_req: '角色是必需的',
  city_req: '城市需要',
  state_req: '要求国家',
  country_req: '国家是必需的。',
  country: '国家',
  zipcode: '邮政编码（可选）',
  enter_phone: '输入电话号码',
  timezone: '时区',
  timezone_req: '时区是必需的',
  user_mgmt: '用户管理',
  user_mgmt_vol: '用户管理 - 志愿者',
  user_mgmt_aed: '用户管理 -  AED业主',
  user_mgmt_disp: '用户管理 - 管理/调度',
  user_mgmt_juri: '用户管理 - 管辖权',
  user_mgmt_aed_equipment: '用户管理 -  AED设备',
  vol_details: '志愿者详情 - ',
  aed_details: 'AED所有者的详细信息 - ',
  admin_details: '管理员详细信息 - ',
  '': '',
  disp_details: '调度细节 - ',
  dispAdmin_detais: '调度管理细节 - ',
  create_user: '创建用户',
  sca_events: 'SCA活动',
  ongoing_sca_events: '正在进行的SCA事件',
  past_events: '过去的事',
  event_details: '活动详情',
  dashboard: '仪表板',
  no_data_yet: '暂无数据',
  add_jurisdiction: '添加管辖权',
  new_jurisdiction: '新的管辖',
  create_jurisdiction: '创建管辖权',
  edit_jurisdiction: '保存管辖权',
  jurisdiction_details: '辖详细',
  label_of_the_jurisdiction: '标签管辖权',
  not_suporting_jurisdiction:
    '目前我们不支持这个国家创造管辖权。请与管理员联系。',
  confirm_event_close: '你确定要关闭这个事件？',
  confirm_notify_again: '你确定你想再次通知可用的志愿者？',
  confirm_delete_area: '你确定要删除选定的司法管辖区？',
  confirm_delete_aeds: '你确定要删除所选的抗癫痫药物？',
  confirm_impersonate_exit: '你想退出冒充模式？',
  confirm_impersonate: '你确定你想进入冒充模式',
  confirm_delete_users: '你确定要删除选定的用户？',
  confirm_delete: '你确定你要删除？',
  yes: '是',
  no: '没有',
  something_wrong: '出了些问题！！！',
  select_state: '选择国家',
  select_cities: '选择城市',
  select_zipcode: '选择邮编',
  select_counties: '选择县',
  export_pdf: '导出PDF',
  share_pdf: '分享PDF',
  resend_credentials: '重发证书',
  add_user: '添加用户',
  jurisdiction_req: '管辖权是必需的。',
  jurisdiction_name_req: '辖名称是必需的。',
  county_req: '请至少选择一个县。',
  states_req: '请选择至少一个国家。',
  cities_req: '请至少选择一个城市。',
  zipcodes_req: '请选择至少一个邮政编码。',
  jurisdiction: '管辖权',
  select_jurisdiction: '选择管辖',
  no_volunteers: '没有志愿者呢。',
  no_aed_owners: '没有AED业主呢。',
  no_jurisdictions: '任何司法管辖区呢。',
  no_user: '没有管理员，调度员或管理员调度还。',
  no_sca: '还没有任何活动。',
  no_aed_equipment: '没有AED设备呢。',
  no_activities: '没有活动呢。',
  create_sca_success: 'SCA事件创建成功。',
  sca_expired: '事件已过期，由于系统超时',
  search_location: '搜索某个地点....',
  latitude: '纬度',
  longitude: '经度',
  enter_sca_address: '输入SCA活动地址',
  or_enter_latLong: '或者输入纬度和经度',
  or_search_address: '或搜索的地址',
  invalid_signature: '无效的签名！',
  search: '搜索…',
  close: '关',
  internet_disconnected: '互联网已经断开。请尽量排除故障的网络设置。',
  internet_connected: '您现在已经连接！',
  start_adding: '开始下面的按钮添加一些，通过点击',
  generate_report: '生成报告',
  sca_attributes: 'SCA属性',
  volunteer_attributes: '志愿者属性',
  aed_equip_attributes: 'AED装备属性',
  select_certificates: '选择证书',
  reports: '报告',
  sca_event_reports: 'SCA事件报告',
  volunteer_reports: '志愿者报告',
  aed_reports: 'AED设备',
  clear_filters: '清除过滤器',
  valid_date: '请选择有效日期范围！',
  select_date_from: '选择日期从',
  select_date_to: '选择日期到',
  sca_event_history: 'SCA事件历史记录',
  sca_event_detail: 'SCA事件详细信息',
  aed_not_imported:
    '无法汇入下列抗癫痫药物由于无效数据。请输入有效的数据成功导入AED！',
  no_volunteer_responded: '没有志愿者已经响应',
  import_aed: '进口AED',
  import_csv: '导入CSV',
  edit_csv: '编辑CSV数据',
  create_aed_owner: '创建AED业主',
  assign_aed: '分配AED',
  cancel: '取消',
  confirm_unassign_Aed: '你确定要取消分配此AED？',
  no_result: '没有找到记录！',
  share: '分享',
  enter_email: '输入电子邮件，按`Enter`',
  email_not_valid: '是不是一个有效的电子邮件地址。',
  email_already_added: '已添加。',
  responded_event: '回应事件。',
  en_route_aed: '途中AED。',
  en_route_sca_with_aed: '途中与AED的SCA事件。',
  aed_equipped: 'AED设备配备',
  en_route_sca_without_aed: '途中没有AED的SCA事件。',
  aed_not_found: '没有找到AED。',
  en_route_sca: '途中SCA事件。',
  arrived_sca: '在SCA到达指定地点',
  ems_arrived: 'EMS到达',
  event_expire_auto: '事件自动失效。',
  exited_sca: '已退出SCA',
  exited_sca_eta: '被迫由系统事件退出由于较高的ETA。',
  start_typing_initials: '开始输入声母',
  select_all: '全选',
  version: '版',
  impersonating_as: '冒充的',
  exit_impersonating: '退出冒充模式',
  logout: '登出',
  upload: '上传',
  mandatory_fields: '请确保以下字段的CSV文件中强制性的。',
  manufacturer: '生产厂家',
  model_number: '型号',
  serial_number: '序列号',
  installation_date: '安装日期',
  location_details: '详细地点',
  pad_details: '垫详情',
  battery_details: '电池详情',
  aed_detail: 'AED详细',
  aed_image: 'AED图片',
  street_view_image: '街景照片',
  street_image: '街图片',
  last_checked_on: '最后查了',
  site_name: '网站名称',
  floor_sub_location: '地板/分位置',
  location: '位置',
  notes: '笔记',
  availability: '可用性',
  public: '上市',
  private: '私人的',
  zoll_equipments: 'ZOLL医疗设备',
  Others: '其他',
  optional: '可选的',
  volunteer_detail: '志愿者详细',
  new_alert: '新的警报',
  aeds_nearby: '附近的抗癫痫药物',
  volunteers_nearby: '附近的志愿者',
  send_alert_to_all: '发送警报到附近的SCA位置的所有的志愿者活动。',
  geolocation_not_enabled: '地理位置未启用。请启用使用此功能。',
  geolocation_not_supported: '不支持地理定位！',
  search_sca_area: '搜索SCA区，PIN码...',
  active_sca: '活动SCA事件',
  no_active_case: '无活动案例',
  volunteers_accepted_event: '志愿者接受了事件',
  no_one_accepted_event: '没有人接受了SCA事件还。',
  ems_onsite: 'EMS上门',
  started_at: '在开始',
  pass_change_succ: '密码更换成功！',
  something_wrong_try_later: '发生错误。请稍后再试！',
  password_hint_text:
    '密码应至少为6个字符。它应该有至少1个符号或数字和1个大写字母。',
  back_to_login: '回到登入',
  select_duration_sca: 'SCA事件的持续时间选择',
  lt_5_mins: '不到5分钟',
  range5to10: '5  -  10分钟',
  gt_10_mins: '超过10分钟',
  event_response: '事件响应',
  all: '所有',
  no_response: '没有反应',
  assisted: '辅助',
  not_assisted: '没有辅助',
  non_assisted: '非辅助',
  at: '在',
  date_range: '日期范围',
  role: '角色',
  only_volunteer: '只有志愿者',
  also_aed_owner: '还有一个AED主人',
  also_volunteer: '另外一个志愿者',
  volunteer_certifications: '志愿者认证类型',
  aed_equipment: 'AED设备',
  registered_aed: '注册AED',
  imported_aed: '进口AED',
  manufacturer_and_model: '制造商和型号',
  aed_owners: 'AED业主',
  created_by_admin: '创建由Admin',
  created_by_app: '通过创建移动应用',
  owner_name: 'AED所有者的姓名',
  organization_name: '机构名称',
  number_aed: 'AED设备数量',
  devices: '设备',
  more: '更多',
  new: '新',
  users: '用户',
  admins: '管理员',
  dispatcher: '调度员',
  dispatch_admins: '调度管理员',
  user_name: '用户名',
  user_type: '用户类型',
  jurisdictions: '司法管辖区',
  jurisdiction_labels: '辖标签',
  areas_covered: '涉及的领域',
  counties: '县',
  cities: '城市',
  volunteers: '志愿者',
  active_users: '活跃用户',
  non_active_users: '非活跃用户',
  pending_approval: '待批准',
  volunteer_name: '志愿者的姓名',
  certifications: '认证',
  address: '地址',
  status: '状态',
  today: '今天',
  joined_as: '加入AED链路作为',
  added: '添加',
  removed: '删除',
  added_jurisdiction: '新增管辖权',
  removed_jurisdiction: '删除了管辖权',
  activated_volunteer: '激活志愿者',
  de_activated_volunteer: '去激励志愿者',
  changed_role_from: '从角色转换',
  to: '至',
  last_week: '上个星期',
  this_month: '这个月',
  last_month: '上个月',
  last_2_month: '最近2个月',
  zoll_aed_equipment: 'ZOLL AED设备',
  other_aed_equipment: '其他品牌AED设备',
  breathing_restored: '吸气还原',
  breathing_not_restored: '呼吸未恢复',
  owner_details: '所有者的详细信息',
  customer_name: '顾客姓名',
  access: '访问',
  timings: '计时',
  created_by: '由...制作',
  aed_devices: 'AED设备',
  sca_events_served: 'SCA活动服务',
  total_volunteers: '共有志愿者',
  total_aed_equipment: '总AED设备',
  quick_analysis: '快速分析',
  average_sca_completion: 'SCA平均完成时间',
  minutes: '分钟',
  aed_equipments_registered: 'AED设备注册',
  ratio_of_gender: '患者的性别比例',
  ratio_of_age: '的患者年龄比',
  ratio_of_breathing: '患者的比例是其呼吸恢复',
  average_between_sca_acceptance_first_shock:
    'SCA接受和第一次电击之间的平均交付时间',
  sca_closed_succ: 'SCA活动圆满结束',
  sca_event_location: 'SCA活动地点',
  sca_dispatched_by: 'SCA通过调度',
  date_and_time: '约会时间',
  event_log: '事件簿',
  survey_report: '调查报告',
  survey_from_volunteers: '从志愿者调查报告',
  volunteers_notified: '志愿者通知',
  volunteer_address: '志愿者的地址',
  admin: '管理员',
  dispatchAdmin: '调度管理',
  mobile_app: '移动应用',
  settings: '设置',
  preferred_language: '请从下面的首选语言',
  language: '语言',
  time_zone_admin: '这是你的时区由管理员设置的。',
  jurisdiction_info: '辖资讯',
  arriving_in: '抵达',
  min: '分',
  download_app: '下载的应用程序',
  volunters_save_lives: '组织志愿者救人。',
  demo_description:
    '我们的应用程序可以帮助志愿者追踪心脏骤停的受害者，并提供一切可能的支持EMS到达之前。下面是我们如何做到这一点。',
  mobile_app_btn: '移动应用',
  web_dash_btn: '网络信息中心',
  expolore_app: '点击此处查看RescuerLink演示',
  demo_login: '登录',
  male: '男',
  female: '女',
  unknown: '未知',
  years_0TO_5: '0  -  5年',
  years_5TO10: '5  -  10年',
  years_10TO15: '10  -  15年',
  years_15TO20: '15  -  20年',
  years_20TO25: '20  -  25年',
  years_25TO30: '25  -  30年',
  years_30TO35: '30  -  35年',
  years_35TO40: '35  -  40年',
  years_40TO45: '40  -  45年',
  years_45TO50: '45  -  50年',
  years_50TO55: '50  -  55年',
  years_55TO60: '55  -  60年',
  years_60TO65: '60  -  65年',
  years_65TO70: '65  -  70年',
  years_70TO75: '70  -  75年',
  years_75TO80: '75  -  80年',
  years_80TO85: '80  -  85年',
  years_85TO90: '85  -  90年',
  GT_90_years: '90+年',

  gender_question: '什么是病人的性别？',
  age_question: '病人的估计年龄？',
  cpr_given: '前911的到来是CPR给出？',
  cpr_given_time: 'CPR交货的估计时间？',
  pads_placed: '是的AED垫置于患者？',
  pads_placed_time: '预计时间当垫放在哪里？',
  aed_turned_on: '在AED开启？',
  aed_turned_on_time: '预计时间，当它被打开？',
  patient_shocked: '难道AED不断震荡病人？',
  patient_shocked_time: '估计时间和冲击次数交付？',
  number_of_shocks: '回车冲击次数交付',
  breathing_restored_question: '难道患者有自己的呼吸恢复。',
  breathing_restored_time: '预计时间，当呼吸恢复？',
  transported_hospital: '是病人运送到医院？',
  other_comments: '其他意见/关注的不是引用了可能对医生有用吗？',
  restart_demo: '重新启动演示',
  copy_auth_token: '复制身份验证令牌',
  copied_to_clipboard: '复制到剪贴板！',
};
