/* German Language */
export default {
  email: 'Email',
  password: 'Passwort',
  phone_number: 'Telefonnummer',
  email_required: 'E-Mail ist erforderlich',
  password_required: 'Passwort wird benötigt',
  email_valid: 'E-Mail muss eine gültige E-Mail',
  password_regex_message: 'Kennwort ist ungültig',
  password_min: 'Das Passwort muss mindestens 6 Zeichen',
  login: 'Weiter zum Login',
  login_account: 'Ins Konto einloggen.',
  forgot_password: 'Passwort vergessen',
  account: 'Haben Sie kein Konto?',
  contact_support: 'Kontaktieren Sie Support',
  send_recovery_link: 'Bitte senden Sie mir eine Erholung Link',
  register_email:
    'Wir werden Ihnen einen Passwort-Reset-Link auf Ihrem registrierten E-Mail-Konto senden.',
  enter_your_email: 'geben sie ihre E-Mail Adresse ein',
  reset_password: 'Passwort zurücksetzen',
  looks_new_account: 'Sieht aus wie ein neues Konto',
  create_new_password_text: 'Bitte legen Sie ein neues Passwort',
  new_password: 'Neues Passwort',
  confirm_password: 'Kennwort bestätigen',
  create_password: 'Passwort erstellen',
  password_not_match: 'Passwort stimmt nicht überein',
  track_event: 'Laufdisziplin',
  resend_alert: 'Erneut senden Benachrichtigungs',
  first_name: 'Vorname',
  last_name: 'Familienname, Nachname',
  contact: 'Kontakt Nummer',
  organization: 'Organisation',
  save_changes: 'Änderungen speichern',
  assume_role: 'nehmen Rollen',
  user_privilege: 'Benutzer Privilege',
  activities: 'Aktivitäten',
  apartment: 'Apartment / Suite / Büronummer',
  take_me_back: 'Nimm mich zurück',
  page_not_found: 'Seite nicht gefunden',
  page_not_found_text:
    'Die Seite, die Sie Das versuchen, nicht existiert zu erreichen.',
  create_alert: 'Benachrichtigung erstellen',
  creating_alert: 'Erstellen eines Benachrichtigungs',
  state: 'Zustand',
  city: 'Stadt',
  sca_history: 'SCA Geschichte',
  send_sca_alerts: 'SCA Benachrichtigungen senden',
  sending_sca_alerts: 'SCA sendet Benachrichtigungen',
  firstname_req: 'Vorname ist erforderlich',
  lastname_req: 'Nachname ist erforderlich',
  phone_req: 'Telefonnummer ist erforderlich',
  phone_valid: 'Bitte geben Sie eine gültige Telefonnummer ein!',
  email_req: 'E-Mail ist erforderlich',
  role_req: 'Die Rolle ist erforderlich,',
  city_req: 'Stadt ist erforderlich',
  state_req: 'Staat ist erforderlich',
  country_req: 'Land erforderlich ist.',
  country: 'Land',
  zipcode: 'Postleitzahl (optional)',
  enter_phone: 'Telefonnummer eingeben',
  timezone: 'Zeitzone',
  timezone_req: 'Zeitzone erforderlich',
  user_mgmt: 'Benutzerverwaltung',
  user_mgmt_vol: 'Benutzerverwaltung - Freiwillige',
  user_mgmt_aed: 'Benutzerverwaltung - AED Owners',
  user_mgmt_disp: 'Benutzerverwaltung - Admin / Dispatcher',
  user_mgmt_juri: 'Benutzerverwaltung - Zuständigkeit',
  user_mgmt_aed_equipment: 'Benutzerverwaltung - AED Geräte',
  vol_details: 'Volunteer Details -',
  aed_details: 'AED Management Details -',
  admin_details: 'Admin Details -',
  '': '',
  disp_details: 'Dispatcher Details -',
  dispAdmin_detais: 'Dispatch Server-Betreiber Details -',
  create_user: 'Benutzer erstellen',
  sca_events: 'SCA Events',
  ongoing_sca_events: 'SCA Laufende Veranstaltungen',
  past_events: 'vergangene Ereignisse',
  event_details: 'Veranstaltungsdetails',
  dashboard: 'Instrumententafel',
  no_data_yet: 'Daten noch nicht',
  add_jurisdiction: 'In der Zuständigkeit',
  new_jurisdiction: 'neue Zuständigkeit',
  create_jurisdiction: 'erstellen Zuständigkeit',
  edit_jurisdiction: 'Speichern Zuständigkeit',
  jurisdiction_details: 'Stand-Details',
  label_of_the_jurisdiction: 'Beschriften der Jurisdiktions',
  not_suporting_jurisdiction:
    'Zur Zeit sind wir unterstützen esta Land nicht zuständig zu erstellen. Bitte kontaktieren Sie Administrator.',
  confirm_event_close:
    'Sind Sie sicher, dass Sie mir diese Veranstaltung schließen möchten?',
  confirm_notify_again:
    'Sind Sie sicher, dass Sie wieder zur Verfügung Freiwilligen benachrichtigen möchten?',
  confirm_delete_area:
    'Sind Sie sicher, dass Sie ausgewählt Jurisdiktionen löschen?',
  confirm_delete_aeds: 'Sind Sie sicher, dass Sie ausgewählt AEDs löschen?',
  confirm_impersonate_exit: 'Wollen Sie Identitätswechsel-Modus verlassen?',
  confirm_impersonate:
    'Sind Sie sicher, dass Sie als impersonating Modus eingeben möchten',
  confirm_delete_users:
    'Sind Sie sicher, dass Sie ausgewählte Benutzer löschen?',
  confirm_delete: 'Sind Sie sicher, dass Sie löschen möchten?',
  yes: 'Und es ist',
  no: 'Nein',
  something_wrong: 'Etwas ist schief gelaufen !!!',
  select_state: 'Wählen Staaten',
  select_cities: 'Wählen Städte',
  select_zipcode: 'Wählen Sie Zipcodes',
  select_counties: 'Wählen Sie Counties',
  export_pdf: 'Export PDF',
  share_pdf: 'Teilen PDF',
  resend_credentials: 'Erneut senden Credentials',
  add_user: 'Nutzer hinzufügen',
  jurisdiction_req: 'Gerichtsstand ist nicht erforderlich.',
  jurisdiction_name_req: 'Zuständigkeit Name ist erforderlich.',
  county_req: 'Bitte wählen Sie mindestens ein County.',
  states_req: 'Bitte wählen Sie mindestens einen Staat.',
  cities_req: 'Bitte wählen Sie mindestens eine Stadt.',
  zipcodes_req: 'Bitte wählen Sie mindestens eine Postleitzahl.',
  jurisdiction: 'Zuständigkeit',
  select_jurisdiction: 'Wählen Sie die gerichtliche Zuständigkeit',
  no_volunteers: 'Keine Freiwilligen noch.',
  no_aed_owners: 'Besitzer noch nicht AED.',
  no_jurisdictions: 'Gerichtsbarkeiten noch nicht.',
  no_user: 'No Admin, Versand Dispatcher oder Admin noch.',
  no_sca: 'Noch keine Events.',
  no_aed_equipment: 'Keine AED Geräte vor.',
  no_activities: 'Noch keine Aktivitäten.',
  create_sca_success: 'SCA Ereignis erfolgreich erstellt.',
  sca_expired:
    'Ereignis ist abgelaufen aufgrund Sie waren bereits im Time-out-System',
  search_location: 'Suchen Sie nach einem Ort ....',
  latitude: 'Breite',
  longitude: 'Längengrad',
  enter_sca_address: 'Geben Sie SCA Ereignisadresse',
  or_enter_latLong: 'Geben Sie Breite und Länge Or',
  or_search_address: 'Oder eine Adresssuche',
  invalid_signature: 'Ungültige Signatur !!',
  search: 'Suche ...',
  close: 'Schließen',
  internet_disconnected:
    'Internet has-been getrennt. Bitte versuchen Sie die Netzwerkeinstellungen zur Fehlerbehebung.',
  internet_connected: 'Sie sind jetzt verbunden!',
  start_adding: 'Starten Sie das Hinzufügen einige, indem Sie auf den Button',
  generate_report: 'Bericht generieren',
  sca_attributes: 'SCA Attribute',
  volunteer_attributes: 'Volunteer Attribute',
  aed_equip_attributes: 'AED Geräte-Attribute',
  select_certificates: 'Wählen Sie Zertifikate',
  reports: 'Berichte',
  sca_event_reports: 'SCA Ereignisberichte',
  volunteer_reports: 'Freiwillige Berichte',
  aed_reports: 'AED-Ausrüstungen',
  clear_filters: 'Filter löschen',
  valid_date: 'Bitte wählen Sie gültigen Datumsbereich!',
  select_date_from: 'Wählen Sie Datum aus',
  select_date_to: 'Wählen Sie Datum, um',
  sca_event_history: 'SCA Event History',
  sca_event_detail: 'SCA Detail der Veranstaltung',
  aed_not_imported:
    'Konnte nicht importiert werden aufgrund AEDs ungültige Daten folgen. Bitte geben Sie eine gültige Importdaten AED erfolgreich!',
  no_volunteer_responded: 'Kein Freiwilliger hat geantwortet',
  import_aed: 'Import AED',
  import_csv: 'CSV-Import',
  edit_csv: 'Bearbeiten von CSV-Daten',
  create_aed_owner: 'Erstellen AED Besitzer',
  assign_aed: 'ASSIGN AED',
  cancel: 'Stornieren',
  confirm_unassign_Aed: 'Sind Sie sicher, Sie wollen unassign esta AED?',
  no_result: 'Keine Aufzeichnungen gefunden!',
  share: 'Compartir',
  enter_email: 'E-Mail und drücken Sie die Eingabetaste `Enter`',
  email_not_valid: 'ist keine gültige E-Mail-Adresse.',
  email_already_added: 'Bereits wurde hinzugefügt.',
  responded_event: 'Reagiert auf das Ereignis.',
  en_route_aed: 'auf dem Weg zum AED.',
  en_route_sca_with_aed: 'auf dem Weg zum SCA-Event mit einem AED.',
  aed_equipped: 'AED-Gerät ausgestattet ist',
  en_route_sca_without_aed: 'auf dem Weg zum SCA Ereignisse ohne AED.',
  aed_not_found: 'Haben Sie nicht den AED finden.',
  en_route_sca: 'in dem Weg zum SCA-Ereignisse.',
  arrived_sca: 'Wir kamen im SCA Ort',
  ems_arrived: 'EMS angekommen',
  event_expire_auto: 'Ereignis abgelaufen automatisch.',
  exited_sca: 'Exited SCA',
  start_typing_initials: 'Start Typing Initialen',
  select_all: 'Wählen Sie Alle',
  version: 'Ausführung',
  impersonating_as: 'Identitätswechsel als',
  exit_impersonating: 'Impersonating Ausgangsmodus',
  logout: 'Ausloggen',
  upload: 'Hochladen',
  mandatory_fields:
    'Bitte stellen Sie sicher, dass die folgenden Felder sind Pflicht Das in der CSV-Dokument.',
  manufacturer: 'Hersteller',
  model_number: 'Modell-Nr',
  serial_number: 'Ordnungsnummer',
  installation_date: 'Installationsdatum',
  location_details: 'Standortinformationen',
  pad_details: 'Pad-Details',
  battery_details: 'Batterie Details',
  aed_detail: 'AED Details',
  aed_image: 'AED Bild',
  street_view_image: 'Street View-Bild',
  street_image: 'Straßenbild',
  last_checked_on: 'Zuletzt überprüft am',
  site_name: 'Site Name',
  floor_sub_location: 'Boden / Unter Standort',
  location: 'Ort',
  notes: 'Anmerkungen',
  availability: 'Verfügbarkeit',
  public: 'Öffentlichkeit',
  private: 'Privat',
  zoll_equipments: 'Zoll Medizinische Geräte',
  Others: 'Andere',
  optional: 'Optional',
  volunteer_detail: 'Volunteer-Detail',
  new_alert: 'neue Warnung',
  aeds_nearby: 'AEDs in der Nähe',
  volunteers_nearby: 'Freiwillige in der Nähe',
  send_alert_to_all:
    'Warnung senden an alle aktiven Freiwilligen SCA Nähe zur Verfügung.',
  geolocation_not_enabled:
    'Geolokalisierung ist nicht aktiviert. Bitte verwenden Sie diese Funktion zu aktivieren.',
  geolocation_not_supported: 'Geolokalisierung wird nicht unterstützt!',
  search_sca_area: 'Suchen SCA-Bereich, Pin-Code ...',
  active_sca: 'Aktive SCA Ereignisse',
  no_active_case: 'Keine aktiven Hüllen',
  volunteers_accepted_event: 'Freiwillige nahm die Veranstaltung',
  no_one_accepted_event: 'Niemand hat bisher das SCA-Ereignis akzeptiert.',
  ems_onsite: 'Vor-Ort-EMS',
  started_at: 'Fing an bei',
  pass_change_succ: 'Das Passwort wurde erfolgreich geändert!',
  something_wrong_try_later:
    'Ging etwas schief, bitte versuchen Sie es später noch einmal!',
  password_hint_text:
    'Das Passwort sollte mindestens 6 Zeichen lang sein. Es sollte mindestens ein Symbol hat oder eine Nummer und einen Großbuchstaben.',
  back_to_login: 'Zurück zur Anmeldung',
  select_duration_sca: 'Wählen Sie die Dauer von SCA Veranstaltung',
  lt_5_mins: 'Weniger als 5 Minuten',
  range5to10: '5 bis 10 Minuten',
  gt_10_mins: 'Mehr als 10 Minuten',
  event_response: 'Ereignisantwort',
  all: 'Alle',
  no_response: 'keine Antwort',
  assisted: 'Assisted',
  not_assisted: 'nicht Assisted',
  non_assisted: 'nicht unterstützt',
  at: 'beim',
  date_range: 'Datumsbereich',
  role: 'Rolle',
  only_volunteer: 'nur Freiwillige',
  also_aed_owner: 'Auch ein Besitzer AED',
  also_volunteer: 'Auch ein Volunteer',
  volunteer_certifications: 'Volunteer Zertifizierungen',
  aed_equipment: 'AED Geräte',
  registered_aed: 'registrierte AED',
  imported_aed: 'Importierte AED',
  manufacturer_and_model: 'Hersteller und Modell',
  aed_owners: 'AED Owners',
  created_by_admin: 'Erstellt von Admin',
  created_by_app: 'Erstellt von Mobile Application',
  owner_name: 'AED Name des Besitzers',
  organization_name: 'Name der Organisation',
  number_aed: 'Anzahl der AED-Geräte',
  devices: 'Geräte',
  more: 'Mehr',
  new: 'Neu',
  users: 'Benutzer',
  admins: 'Admins',
  dispatcher: 'Dispatcher',
  dispatch_admins: 'Dispatch-Admins',
  user_name: 'Benutzername',
  user_type: 'Benutzertyp',
  jurisdictions: 'Gerichtsbarkeiten',
  jurisdiction_labels: 'Stand Labels',
  areas_covered: 'Abgedeckte Gebiete',
  counties: 'Counties',
  cities: 'Städte',
  volunteers: 'Freiwillige',
  active_users: 'aktive User',
  non_active_users: 'Nicht aktive Benutzer',
  pending_approval: 'Pending Approval',
  volunteer_name: 'Volunteer Name',
  certifications: 'Zertifizierungen',
  address: 'Adresse',
  status: 'Status',
  today: 'Heute',
  joined_as: 'als AED Link verbunden',
  added: 'Hinzugefügt',
  removed: 'entfernt',
  added_jurisdiction: 'Hinzugefügt Stand',
  removed_jurisdiction: 'entfernt Zuständigkeit',
  activated_volunteer: 'aktiviert Freiwillige',
  de_activated_volunteer: 'De-activated Freiwillige',
  changed_role_from: 'Veränderte Rolle von',
  to: 'zu',
  last_week: 'Woche Letzte',
  this_month: 'Diesen Monat',
  last_month: 'Letzten Monat',
  last_2_month: 'Letzte 2 Monate',
  zoll_aed_equipment: 'Zoll AED Geräte',
  other_aed_equipment: 'Andere Marken AED Geräte',
  breathing_restored: 'Atmung wieder hergestellt',
  breathing_not_restored: 'Atmung nicht wiederhergestellt',
  owner_details: 'Vermieter Details',
  customer_name: 'Kundenname',
  access: 'Zugriff',
  timings: 'Timings',
  created_by: 'Erstellt von',
  aed_devices: 'AED-Geräte',
  sca_events_served: 'SCA serviert Veranstaltungen',
  total_volunteers: 'insgesamt Freiwillige',
  total_aed_equipment: 'Insgesamt AED Geräte',
  quick_analysis: 'Schnellanalyse',
  average_sca_completion: 'SCA Durchschnittliche Zeit bis zur Fertigstellung',
  minutes: 'Protokoll',
  aed_equipments_registered: 'Registrierte AED Geräte',
  ratio_of_gender: 'Verhältnis von Patienten Geschlecht',
  ratio_of_age: 'Verhältnis von Alter der Patienten',
  ratio_of_breathing: 'Verhältnis der Atmung des Patienten, das restaurierte',
  average_between_sca_acceptance_first_shock:
    'Die durchschnittliche Zeit zwischen Annahme und dem ersten SCA Schock abgegeben',
  sca_closed_succ: 'SCA erfolgreich geschlossen Ereignis',
  sca_event_location: 'SCA Event Location',
  sca_dispatched_by: 'Wird ausgelöst durch SCA',
  date_and_time: 'Terminzeit',
  event_log: 'Ereignisprotokoll',
  survey_report: 'Umfragebericht',
  survey_from_volunteers: 'Survey Report von Freiwilligen',
  volunteers_notified: 'Freiwillige Benannte',
  volunteer_address: 'Freiwilligen-Adresse',
  admin: 'Administrator',
  dispatchAdmin: 'Dispatch Admin',
  mobile_app: 'Mobile Applikation',
  settings: 'die Einstellungen',
  preferred_language: 'Bitte wählen Sie die gewünschte Sprache von unten',
  language: 'Sprache',
  time_zone_admin: 'Das ist Ihre Zeitzone durch den Admin ein.',
  jurisdiction_info: 'Gerichtsstand Infos',
  arriving_in: 'Ankunft in',
  min: 'Mindest',
  download_app: 'Laden Sie die App',
  volunters_save_lives: 'Unsere Freiwilligen zu organisieren, Leben zu retten.',
  demo_description:
    'Unser App Volunteer hilft den Herzstillstand Opfer verfolgen und jede mögliche Unterstützung bereitstellen, bevor die EMS ankommen. Hier ist, wie wir es tun.',
  mobile_app_btn: 'App',
  web_dash_btn: 'Web-Dashboard',
  expolore_app: 'Klicken Sie hier, um zu sehen, RescuerLink Demo',
  demo_login: 'Anmeldung',
  male: 'Männlich',
  female: 'weiblich',
  unknown: 'Unbekannt',
  years_0TO_5: '0-5 Jahre',
  years_5TO10: '5 bis 10 Jahre',
  years_10TO15: '10 bis 15 Jahre',
  years_15TO20: '15 bis 20 Jahre',
  years_20TO25: '20 bis 25 Jahre',
  years_25TO30: '25 bis 30 Jahre',
  years_30TO35: '30 - 35 Jahre',
  years_35TO40: '35-40 Jahre',
  years_40TO45: '40-45 Jahre',
  years_45TO50: '45-50 Jahre',
  years_50TO55: '50-55 Jahre',
  years_55TO60: '55-60 Jahre',
  years_60TO65: '60-65 Jahre',
  years_65TO70: '65-70 Jahre',
  years_70TO75: '70-75 Jahre',
  years_75TO80: '75-80 Jahre',
  years_80TO85: '80-85 Jahre',
  years_85TO90: '85-90 Jahre',
  GT_90_years: '90+ Jahre',
  gender_question: 'Was war das Geschlecht des Patienten?',
  age_question: 'Geschätztes Alter des Patienten?',
  cpr_given: 'War CPR vor 911 Bei der Ankunft?',
  cpr_given_time: 'CPR geschätzte Lieferzeit?',
  pads_placed: 'Wurden die AED-Pads auf dem Patienten platziert?',
  pads_placed_time: 'Geschätzte Zeit Wenn Pads gesetzt wurden?',
  aed_turned_on: 'AED eingeschaltet wurde?',
  aed_turned_on_time: 'Geschätzte Zeit, als es eingeschaltet?',
  patient_shocked: 'Hat Schock der AED den Patienten überhaupt?',
  patient_shocked_time: 'Geschätzte Zeit und Anzahl der abgegebenen Schocks?',
  number_of_shocks: 'Geben Sie die Anzahl der abgegebenen Schocks',
  breathing_restored_question:
    'Hat der Patient die Atmung wieder hergestellt haben.',
  breathing_restored_time:
    'Geschätzte Zeit, wenn die Atmung wieder hergestellt wurde?',
  transported_hospital: 'Wurde der Patient ins Krankenhaus transportiert?',
  other_comments:
    'Kommentare / Bedenken, die nicht nützlich für den Arzt verwiesen werden können?',
  restart_demo: 'Neustart Demo',
  copy_auth_token: 'Kopieren Auth-Token',
  copied_to_clipboard: 'In die Zwischenablage kopiert!',
};
